$(document).ready(function() {
    if ($("#menuToggle").is(":visible")) {
        var mobile_gate_localstorage_key = "eep_mobile_gate";
        var mobile_gate_ttl = 86400000; // 1 day cache

        var text_options = {
            "default": [
                "Continue browsing while logged in",
                "Please join our Engineering community",
                "Create your account and continue reading",
                "Join 20,000+ registered members",
                "Join the largest community of power engineers"
            ]
            /*"forums": [
                "Post on forums"
            ],
            "power-electronics-textbook": [
                "Download Textbook PDF",
                "Log in to download PDF",
                "View full textbook logged in"
            ]*/
        };

        var listener = function(){

            // only show once for duration of localstorage length
            $.jStorage.set(mobile_gate_localstorage_key, 1);
            $.jStorage.setTTL(mobile_gate_localstorage_key, mobile_gate_ttl);

            var segment_1_slug = window.location.pathname.split('/')[1];
            var gate_text;

            if(text_options[segment_1_slug] !== undefined) {
                gate_text = text_options[segment_1_slug][Math.floor(Math.random() * text_options[segment_1_slug].length)];
            } else {
                gate_text = text_options['default'][Math.floor(Math.random() * text_options['default'].length)];
            }

            // send display event
            if(typeof ga !== 'undefined') {
                ga('send', 'event', 'Mobile-Gate', 'Displayed', gate_text);
            }
            if(typeof gtag !== 'undefined') {
                gtag("event", "Mobile-Gate", {
                    event_category: "Displayed",
                    event_label: gate_text,
                  });
            }


            var gate_html =  "<div id=\"mobile-gate\">\n" +
                "    <div class=\"fixed top-0 left-0 w-full h-full opacity-50 bg-black z-30\"></div>\n" +
                "    <div class=\"fixed z-50 bg-white border-t-[10px] border-black left-0 right-0 bottom-0\">\n" +
                "        <img src=\"/images/site/Logo-Large.png\" alt=\"EEPower\" class=\"mx-auto mt-4 w-36 h-auto\" />\n" +
                "        <span class=\"block text-center font-bold text-base mt-4 px-4\">\n" +
                gate_text +
                "        </span>\n" +
                "        <hr class=\"bg-main-300 flex-grow my-5 mx-4\">\n" +
                "        <ul class=\"grid grid-cols-2 gap-x-3 gap-y-5 mx-auto items-center justify-between clearfix px-8\">\n" +
                "            <li class=\"px-3\"><a href=\"/forums/register/connected-accounts/facebook/?setup=1\" class=\"ga-link flex items-center justify-center px-6 py-3 border border-main-200 text-[#0866FF] hover:shadow hover:text-[#0866FF]\" data-category=\"Mobile-Gate\" data-action=\"facebook\" data-label=\"" + gate_text + "\"><img src='/images/site/svg/facebook-colored.svg' alt='Facebook' class='mr-3 w-5' /> Facebook</a></li>\n" +
                "            <li class=\"px-3\"><a href=\"/forums/register/connected-accounts/google/?setup=1\" class=\"ga-link flex items-center justify-center px-6 py-3 border border-main-200 text-black hover:shadow hover:text-black\" data-category=\"Mobile-Gate\" data-action=\"google\" data-label=\"" + gate_text + "\"><img src='/images/site/svg/google-colored.svg' alt='Google' class='mr-3 w-5' /> Google</a></li>\n" +
                "            <li class=\"px-3\"><a href=\"/forums/register/connected-accounts/linkedin/?setup=1\" class=\"ga-link flex items-center justify-center px-6 py-3 border border-main-200 text-[#0A66C2] hover:shadow hover:text-[#0A66C2]\" data-category=\"Mobile-Gate\" data-action=\"linkedin\" data-label=\"" + gate_text + "\"><img src='/images/site/svg/linkedin-colored.svg'  alt='LinkedIn' class='mr-3 w-5' /> Linkedin</a></li>\n" +
                "            <li class=\"px-3\"><a href=\"/forums/register/connected-accounts/github/?setup=1\" class=\"ga-link flex items-center justify-center px-6 py-3 border border-main-200 text-[#24292F] hover:shadow hover:text-[#24292F]\" data-category=\"Mobile-Gate\" data-action=\"github\" data-label=\"" + gate_text + "\"><img src='/images/site/svg/github-colored.svg' alt='Github' class='mr-3 w-5' /> GitHub</a></li>\n" +
                "        </ul>\n" +
                "        <div class=\"w-full text-center mt-3 mb-10\">\n" +
                "            <a class=\"hide-button text-center text-base underline\" href=\"#\">Not right now</a>\n" +
                "        </div>\n" +
                "    </div>\n" +
                "</div>";

            $('body').append(gate_html);

            $('html, body').addClass('overflow-hidden');

            $("#mobile-gate .hide-button").on('click',function (e) {
                e.preventDefault();
                e.stopPropagation();
                $('#mobile-gate').addClass('hidden');
                $('html, body').removeClass('overflow-hidden');

                // send display event
                if(typeof ga !== 'undefined') {
                    ga('send', 'event', 'Mobile-Gate', 'Not Right Now', gate_text);
                }
                if(typeof gtag !== 'undefined') {
                    gtag("event", "Mobile-Gate", {
                        event_category: "Not Right Now",
                        event_label: gate_text,
                      });
                }
            });

        };

        if($.jStorage.get(mobile_gate_localstorage_key, 0) != 1) {
            setTimeout(listener, 90000);
        }
    }
});